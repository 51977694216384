import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import i18next from 'i18next'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Container, List, Box, lighten, Typography } from '@mui/material'
import { isMobile } from "react-device-detect";
import { ThemeProvider } from '@mui/material/styles'

import RecruitTheme from '../../recruit-theme'
import ProjectTheme from '../../project-theme'
import FixedSubNavApp from "../nav_sub_fixed"
import SubNavReactApp from '../nav_sub_react'
import Breadcrumbs from '../breadcrumbs'

import seamlessCarbon from '../../svg/carbonseemlesspattern.svg'

import ja_Trans from '../../locales/ja/main.json'
import en_Trans from '../../locales/en/main.json'
import cn_Trans from '../../locales/cn/main.json'

// #region 
const OutlineApp = lazy(() => import('./outline'));
const SecretApp = lazy(() => import('./secret'));
const AccessApp = lazy(() => import('./access'));
const MainContentApp = lazy(() => import('./maincontent'));
const DigitalApp = lazy(() => import('./digital'));
const DesignApp = lazy(() => import('./design'));
const ToolApp = lazy(() => import('./tool'));
const GaugeApp = lazy(() => import('./gauge'));
const SimlationApp = lazy(() => import('./simulation'));
const StyleDesignApp = lazy(() => import('../styledesign'));
const FlowApp = lazy(() => import('./flow'));
const ProductApp = lazy(() => import('./product'));
const ResultApp = lazy(() => import('./result'));
const MeasureApp = lazy(() => import('./measure'));
const ActivityApp = lazy(() => import('./activity'));
const MeasureFormApp = lazy(() => import('./measure_form'));
const PlantApp = lazy(() => import('./plant'));
const SiteMapApp = lazy(() => import('./sitemap'));
const NotFoundApp = lazy(() => import('./404'));
const RecruitApp = lazy(() => import('./recruit'));
const RecruitRequirementsApp = lazy(() => import('./requirements'));
const VoicesApp = lazy(() => import('./voices'));
const VoiceDetailApp = lazy(() => import('./voice_detail'));
const EventsApp = lazy(() => import('./event'));
// #endregion

const MainApp: React.FC = () => {
    const { t } = useTranslation('main');
    i18next.addResources('ja', 'main', ja_Trans);
    i18next.addResources('en', 'main', en_Trans);
    i18next.addResources('cn', 'main', cn_Trans);
    const titleSuffix = ' | GREENFIX';
    const siteurl = "https://www.greenfix.co.jp"
    return (
        <React.StrictMode>
            <Box component="main" style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flex: 1,
                backgroundColor: "#ffffff1a",
                // @include globalstyle.carbon-wall(right)
                backgroundRepeat: "repeat",
                backgroundBlendMode: "overlay",
                // linear-gradient(45deg, lighten(black, 20%), lighten(black, 20%) 50%, lighten(black, 20%), lighten(black, 60%) 70%, lighten(black, 10%) 80%, lighten(black, 10%))
                backgroundImage: `url(${seamlessCarbon}),linear-gradient(45deg, ${lighten("#000", 0.2)}, ${lighten("#000", 0.2)} 50%, ${lighten("#000", 0.2)}, ${lighten("#000", 0.6)} 70%, ${lighten("#000", 0.1)} 80%, ${lighten("#000", 0.1)})`,
                boxShadow: "-3px 0px 6px rgba(0, 0, 0, 0.3) inset",
            }}>
                <Container maxWidth="lg" disableGutters={isMobile}>
                    <Box display="flex" flexDirection="column" height="100%" padding={0} margin={0}>
                        <Grid item container spacing={0} xs={12} direction="row" sm={12}>
                            <Grid item xs={2} sx={{
                                textAlign: "center",
                                width: "100%",
                                display: isMobile ? "none" : "flex",
                                flexDirection: "column",
                                overflowY: "auto",
                                height: "calc(100vh - 66px)",
                                "&::-webkit-scrollbar": {
                                    display: "none"
                                }
                            }}>
                                <Box height="100%" fontSize="0.8rem"
                                    style={{ backgroundColor: '#00000000' }}>
                                    <Box component="article"
                                        color="white" display="flex" flexDirection="column">
                                        <List>
                                            <SubNavReactApp />
                                        </List>
                                    </Box>
                                    <Box component="article"
                                        color="white" display="flex" flexDirection="column">
                                        <List>
                                            <FixedSubNavApp />
                                        </List>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 10} sx={{
                                backgroundColor: "#f1f1f1",
                                border: "none",
                                overflowY: "auto",
                                p: 2,
                                height: "calc(100vh - 66px)",
                                "&::-webkit-scrollbar": {
                                    display: "none"
                                }
                            }}>
                                <Box display="flex" flexDirection="column">
                                    <Breadcrumbs />
                                    <Suspense fallback={<Box display="flex" alignItems="flex-start" justifyContent="center"><CircularProgress /></Box>}>
                                        <Routes>
                                            {[
                                                {
                                                    path: "/", component: <MainContentApp />, exact: true, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクス株式会社　ホームページ'),
                                                        title: t('HOME'),
                                                        keywords: t('checking Fixture,治具,ゲージ'),
                                                        description: t('検査具、ゲージ、検査治具、CF、検査ツールとデジタルエンジニアリングのグリーンフィクス'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/outline", component: <OutlineApp />, exact: true, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの沿革'),
                                                        title: t('OUTLINE'),
                                                        keywords: t('案内'),
                                                        description: t('半世紀以上にわたってゲージを基本としてモノづくりを行ってきた会社です。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/outline/secret", component: <SecretApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('徹底した機密管理と取引先一覧'),
                                                        title: t('SECRET'),
                                                        keywords: t('機密,管理,納品,実績'),
                                                        description: t('徹底した機密管理方法と取引先についてご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/outline/access/*", component: <AccessApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスへのアクセス'),
                                                        title: t('ACCESS'),
                                                        keywords: t('地図'),
                                                        description: t('弊社へのアクセス方法を地図で見ることが出来ます。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/digital", component: <DigitalApp />, exact: true, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスのデジタル事業'),
                                                        title: t('DIGITAL'),
                                                        keywords: t('DA,アセンブリー'),
                                                        description: t('デジタル空間でシミュレーションするデジタル事業のご紹介です。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/digital/styledesign", component: <StyleDesignApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの製品デザイン事業'),
                                                        title: t('STYLEDESIGN'),
                                                        keywords: t('意匠,車,デザイン'),
                                                        description: t('イメージをスケッチ、クレイ、高精度面データに置き換えていくデザイン業務をご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/digital/design", component: <DesignApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの設計事業'),
                                                        title: t('DESIGN'),
                                                        keywords: t('部品,設計,車,内装'),
                                                        description: t('CATIA等を使った製品の設計業務を人材派遣、委託、請負で対応いたします。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/digital/simulation", component: <SimlationApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスのデジタルシミュレーション事業'),
                                                        title: t('SIMULATION'),
                                                        keywords: t('ロボット,生産,ライン,車'),
                                                        description: t('デジタル空間で最高の効率化を目指すシミュレーション業務をご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/gauge", component: <GaugeApp />, exact: true, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('検査ツール事業'),
                                                        title: t('GAUGE'),
                                                        keywords: t('カーボン,精度,測定'),
                                                        description: t('当社オリジナルの軽くて高精度なカーボン製検査具をご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/gauge/tool", component: <ToolApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('検査ツールとは？'),
                                                        title: t('TOOL'),
                                                        keywords: t('測定,検査具,精密'),
                                                        description: t('検査具についてわかりやすくマンガでご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/gauge/flow", component: <FlowApp />, exact: true, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの業務の流れ'),
                                                        title: t('FLOW'),
                                                        keywords: t('流れ,NC,加工'),
                                                        description: t('最新鋭のNC加工機と高品質な測定設備の紹介と、検査ツール開発の流れをご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/gauge/product", component: <ProductApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの製品'),
                                                        title: t('PRODUCT'),
                                                        keywords: t('製品,検査具,CF'),
                                                        description: t('高精度な製品例とそれぞれの検査具の役割についての説明です。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/gauge/result", component: <ResultApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの納品実績'),
                                                        title: t('RESULT'),
                                                        keywords: t('実績,世界,輸出'),
                                                        description: t('弊社の検査具は世界中でご利用いただいています。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/gauge/flow/measure_form", component: <MeasureFormApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('お問い合わせページ'),
                                                        title: t('FORM'),
                                                        keywords: t('問い合わせ'),
                                                        description: t('測定依頼のお問い合わせフォームです。'),
                                                        robots: "noindex,nofollow",
                                                    },
                                                },
                                                {
                                                    path: "/gauge/flow/plant/*", component: <PlantApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの工場情報'),
                                                        title: t('PLANT'),
                                                        keywords: t('オオクマ,DEA,CMM'),
                                                        description: t('最新鋭のNC加工機と高品質な測定設備のリストです。'),
                                                        robots: "noindex,nofollow",
                                                    },
                                                },
                                                {
                                                    path: "/quality", component: <MeasureApp />, exact: true, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの品質保証事業'),
                                                        title: t('QUALITY'),
                                                        keywords: t('PCF,パネル,精度'),
                                                        description: t('自動車のパネル品質作りこみ活動（PCF）についてご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/quality/measure", component: <MeasureApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの計測業務'),
                                                        title: t('MEASURE'),
                                                        keywords: t('測定,3次元測定,CMM'),
                                                        description: t('三次元測定器（DEA、FARO）を活用した測定サービスについてご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/quality/activity", component: <ActivityApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの品質保証活動'),
                                                        title: t('ACTIVITY'),
                                                        keywords: t('測定,3次元測定,CMM'),
                                                        description: t('三次元測定器（DEA、FARO）を活用した測定サービスについてご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/recruit", component: <RecruitApp />, exact: true, theme: RecruitTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの採用情報'),
                                                        title: t('RECRUIT'),
                                                        keywords: t('採用,検査具,治具,GREEN FIX,グリーンフィクス'),
                                                        description: t('グリーンフィクスの採用情報についてご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/recruit/recruit", component: <RecruitRequirementsApp />, exact: false, theme: RecruitTheme,
                                                    seo: {
                                                        h1: t('グリーンフィクスの求人情報'),
                                                        title: t('RECRUIT'),
                                                        keywords: t('採用,募集要項,検査具,治具,GREEN FIX,グリーンフィクス'),
                                                        description: t('グリーンフィクスの募集要項についてご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/recruit/voice", component: <VoicesApp />, exact: true, theme: RecruitTheme,
                                                    seo: {
                                                        h1: t('先輩社員の声'),
                                                        title: t('VOICE'),
                                                        keywords: t('採用,検査具,治具,先輩社員の声,GREEN FIX,グリーンフィクス'),
                                                        description: t('グリーンフィクスに勤める先輩社員の声をお届けします。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/recruit/voice/*", component: <VoiceDetailApp />, exact: false, theme: RecruitTheme,
                                                    seo: {
                                                        h1: t('先輩社員の声'),
                                                        title: t('VOICE_DETAIL'),
                                                        keywords: t('採用,検査具,治具,先輩社員の声,製造,製造グループ,GREEN FIX,グリーンフィクス'),
                                                        description: t('グリーンフィクスに勤める先輩社員の声をお届けします。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/recruit/event", component: <EventsApp />, exact: false, theme: RecruitTheme,
                                                    seo: {
                                                        h1: t('社内イベント・福利厚生'),
                                                        title: t('EVENT'),
                                                        keywords: t('採用,社内行事,検査具,治具,GREEN FIX,グリーンフィクス'),
                                                        description: t('グリーンフィクスの採用情報・社内行事についてご紹介します。'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: "/sitemap", component: <SiteMapApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('サイトマップ'),
                                                        title: t('SITEMAP'),
                                                        keywords: t('サイトマップ'),
                                                        description: t('サイトマップ'),
                                                        robots: "all",
                                                    },
                                                },
                                                {
                                                    path: '*', component: <NotFoundApp />, exact: false, theme: ProjectTheme,
                                                    seo: {
                                                        h1: t('NOT FOUND'),
                                                        title: t('NOTFOUND'),
                                                        keywords: t('NOTFOUND'),
                                                        description: t('NOTFOUND'),
                                                        robots: "noindex,nofollow",
                                                    },
                                                },
                                            ].map((item, index) =>
                                                <Route key={index} path={item.path} element=
                                                    {
                                                        <ThemeProvider theme={item.theme}>
                                                            <Helmet
                                                                htmlAttributes={{ prefix: "og: http://ogp.me/ns/website#" }}
                                                                title={item.seo.title + titleSuffix}
                                                                meta={[
                                                                    { property: 'og:title', content: item.seo.title + titleSuffix },
                                                                    { property: 'og:type', content: 'website' },
                                                                    { property: 'og:url', content: siteurl + item.path },
                                                                    { property: 'og:image', content: 'https://www.greenfix.co.jp/greenfix_ogp_img.png' },
                                                                    { property: 'og:description', content: item.seo.description },
                                                                    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
                                                                    { name: 'keywords', content: item.seo.keywords },
                                                                    { name: 'description', content: item.seo.description },
                                                                    { name: 'canonical', content: 'canonical' },
                                                                    { name: 'robots', content: item.seo.robots },
                                                                ]}
                                                            />
                                                            <Typography variant="h1" hidden>{item.seo.h1}</Typography>
                                                            {item.component}
                                                        </ThemeProvider>
                                                    } />)}
                                        </Routes>
                                    </Suspense>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box >
        </React.StrictMode >
    );

}

export default MainApp;
