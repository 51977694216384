import { Box, lighten } from '@mui/material';
import React from 'react';
import seamlessCarbon from '../svg/carbonseemlesspattern.svg'

class FooterApp extends React.Component {
    render() {
        return (
            <Box component="footer"
                style={{
                    backgroundRepeat: "repeat",
                    backgroundBlendMode: "overlay",
                    // left ray reflection cfrp
                    backgroundImage: `url(${seamlessCarbon}),linear-gradient(45deg, ${lighten("#000", 0.1)}, ${lighten("#000", 0.2)} 10%, ${lighten("#000", 0.6)} 20%, ${lighten("#000", 0.2)}, ${lighten("#000", 0.1)} 40%, ${lighten("#000", 0.1)})`,
                    width: "100%",
                    height: "20px",
                    fontSize: "0.8rem",
                    backgroundColor: lighten("#000000", 0.2),
                    color: "white",
                    textAlign: "right",
                    boxSizing: "border-box",
                    paddingRight: "20px",
                }}>Greenfix co., ltd.</Box>
        );
    }
}

export default FooterApp;