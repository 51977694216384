import { ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import ja_Trans from '../locales/ja/nav.json'
import en_Trans from '../locales/en/nav.json'
import cn_Trans from '../locales/cn/nav.json'

const SubNavReactApp: React.FC = () => {
    const { t } = useTranslation('nav');
    i18next.addResources('ja', 'nav', ja_Trans);
    i18next.addResources('en', 'nav', en_Trans);
    i18next.addResources('cn', 'nav', cn_Trans);
    var treeMap = [
        {
            title: t('ホーム'), url: "/", child: [
                { title: t('ホーム'), url: "/" },
                { title: t('会社概要'), url: "/outline" },
                { title: t('検査ツール事業'), url: "/gauge" },
                { title: t('デジタル事業'), url: "/digital" },
                { title: t('品質保証事業'), url: "/quality" },
                { title: t('採用情報'), url: "/recruit" },
                { title: t('サイトマップ'), url: "/sitemap" },
            ]
        },
        {
            title: t('会社概要'), url: "/outline/*", child: [
                { title: t('ホーム'), url: "/" },
                { title: t('会社概要'), url: "/outline" },
                { title: t('機密管理'), url: "/outline/secret" },
                { title: t('アクセス'), url: "/outline/access" },
            ]
        },
        {
            title: t('検査ツール事業'), url: "/gauge/*", child: [
                { title: t('ホーム'), url: "/" },
                { title: t('検査ツール事業'), url: "/gauge" },
                { title: t('検査ツールとは？'), url: "/gauge/tool" },
                {
                    title: t('設備・開発フロー'), url: "/gauge/flow", child: [
                        { title: t('お問い合わせ'), url: "/gauge/flow/measure_form" },
                        { title: t('主要設備一覧'), url: "/gauge/flow/plant" },
                    ]
                },
                { title: t('製品例'), url: "/gauge/product" },
                { title: t('実績'), url: "/gauge/result" },
            ]
        },
        {
            title: t('デジタル事業'), url: "/digital/*", child: [
                { title: t('ホーム'), url: "/" },
                { title: t('デジタル事業'), url: "/digital" },
                { title: t('デザイン'), url: "/digital/styledesign" },
                { title: t('製品設計'), url: "/digital/design" },
                { title: t('シミュレーション'), url: "/digital/simulation" },
            ]
        },
        {
            title: t('品質保証事業'), url: "/quality/*", child: [
                { title: t('ホーム'), url: "/" },
                { title: t('品質保証事業'), url: "/quality" },
                { title: t('各種測定サービス'), url: "/quality/measure" },
                { title: t('品質保証活動'), url: "/quality/activity" },
            ]
        },
        {
            title: t('採用情報'), url: "/recruit/*", child: [
                { title: t('ホーム'), url: "/" },
                { title: t('リクルート'), url: "/recruit" },
                { title: t('募集要綱'), url: "/recruit/recruit" },
                { title: t('社内行事'), url: "/recruit/event" },
                { title: t('社員の声'), url: "/recruit/voice" },
            ]
        },
    ];
    return (
        <Routes>
            {treeMap.map(
                (PageParent, index) => {
                    if (PageParent.url === '/') {
                        return (
                            <Route path={PageParent.url} key={index} element={
                                PageParent.child?.map((PageItem, index) => {
                                    return <ListItem key={index} button
                                        component={Link} to={PageItem.url} >
                                        <ListItemText>
                                            {PageItem.title}
                                        </ListItemText>
                                    </ListItem>
                                })
                            } />
                        )
                    } else {
                        return (
                            <Route path={PageParent.url} key={index} element={
                                PageParent.child?.map((PageItem, index) => {
                                    return <ListItem button key={index} component={Link} to={PageItem.url}>
                                        <ListItemText>
                                            {PageItem.title}
                                        </ListItemText>
                                    </ListItem>
                                })
                            } />
                        )
                    }
                }
            )}
        </Routes>
    );
}

export default SubNavReactApp;