import { createTheme, lighten } from "@mui/material";
import { green, pink } from "@mui/material/colors";

const ProjectTheme = createTheme({
    palette: {
        primary: {
            main: green[500],
        },
        secondary: {
            main: pink[500],
        },
    },
    typography: {
        fontFamily: [
            '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', 'Arial', 'sans-serif',
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        ].join(','),
        htmlFontSize: 10,
        fontSize: 12,
        h2: {
            fontSize: "1rem",
            borderBottomColor: String(green[600]),
            fontWeight: "bold",
            height: "1.5rem",
            width: "100%",
            borderBottomStyle: "solid",
            borderBottomWidth: "0.15pc",
            marginBottom: "0.5rem",
            marginTop: "2rem",
        },
        h3: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            verticalAlign: "middle",
            borderBottomColor: String(green[600]),
            fontWeight: "bold",
            width: "fit-content",
            marginTop: "2em",
            background: "linear-gradient( to bottom right," + lighten('#89ba7f', 0.1) + ", " + lighten('#89ba7f', 0.8) + ")",
            borderRadius: "0.3pc",
            boxSizing: "border-box",
            paddingLeft: "1rem",
            paddingRight: "2rem",
            borderColor: String(green[600]),
            borderStyle: "solid",
            borderWidth: "0.1pc",
            marginBottom: "0.5em",
        },
        h4: {
            fontSize: "1rem",
            fontWeight: "bold",
        },
        body1: {
            fontSize: "1rem",
            marginBottom: "0.5em",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

export default ProjectTheme;