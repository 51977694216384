import { Box } from "@mui/material";
// import { makeStyles } from '@mui/material/styles';
import React from "react";

// const useStyles = makeStyles(() => ({
//     '@keyframes spin': {
//         '0%': { transform: 'rotate(0deg)' },
//         '100%': { transform: 'rotate(360deg)' }
//     },
//     "@keyframes before-key": {
//         "0%": { transform: "translateY(-8px)" },
//         "50%": { transform: "translateY(0px) rotateZ(0deg)" },
//         "100 %": { transform: "rotateZ(-45deg)" },
//     },
//     "@keyframes after-key": {
//         "0%": { transform: "translateY(8px)" },
//         "50%": { transform: "translateY(0px) rotateZ(0deg)" },
//         "100%": { transform: "rotateZ(45deg)" },
//     },
//     "@keyframes center-key": {
//         "0%": { visibility: "visible" },
//         "50%": { visibility: "visible" },
//         "55%": { visibility: "hidden" },
//         "100%": { visibility: "hidden" },
//     },
//     "@keyframes before-key-rev": {
//         "0%": { transform: "rotateZ(-45deg)" },
//         "50%": { transform: "translateY(0px) rotateZ(0deg)" },
//         "100%": { transform: "translateY(-8px)" },
//     },
//     "@keyframes after-key-rev": {
//         "0%": { transform: "rotateZ(45deg)" },
//         "50%": { transform: "translateY(0px) rotateZ(0deg)" },
//         "100%": { transform: "translateY(8px)" },
//     },
//     "@keyframes center-key-rev": {
//         "0%": { visibility: "hidden" },
//         "50%": { visibility: "hidden" },
//         "55%": { visibility: "visible" },
//         "100%": { visibility: "visible" },
//     },
// }));
interface IBurgerProps {
    checked: boolean;
}

const BurgerApp: React.FC<IBurgerProps> = (props) => {
    return <React.StrictMode>
        <Box width="auto" height="100%" overflow="visible" zIndex={100}
            display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
            <Box
                width="25px" height="3px" color="white" display="inline-block" position="absolute"
                style={{
                    content: '',
                    backgroundColor: "white",
                    background: "white",
                    transform: props.checked ? "rotateZ(-45deg)" : "translateY(-8px)",
                    animation: "before-key-rev .3s ease-in-out",
                    // animationName: props.checked ? classes["@keyframes before-key"] : classes["@keyframes before-key-rev"],
                    animationDuration: "0.3s",
                    animationTimingFunction: "ease-in-out",
                }}></Box>
            <Box display="block" height="3px" width="25px"
                borderRadius="3px" m="3px" overflow="visible" position="relative"
                visibility={props.checked ? "hidden" : undefined}
                style={{
                    backgroundColor: "white",
                    content: '',
                    cursor: "pointer",
                    // animationName: props.checked ? classes["@keyframes center-key"] : classes["@keyframes center-key-rev"],
                    animationTimingFunction: "ease-in-out",
                    animationDuration: "0.3s",
                }}></Box>
            <Box width="25px" height="3px" color="white"
                position="absolute" display="inline-block"
                style={{
                    content: '',
                    backgroundColor: "white",
                    background: "white",
                    transform: props.checked ? "rotateZ(45deg)" : "translateY(8px)",
                    // animationName: props.checked ? classes["@keyframes after-key"] : classes["@keyframes after-key-rev"],
                    animationTimingFunction: "ease-in-out",
                    animationDuration: "0.3s",
                }}></Box>
        </Box>
    </React.StrictMode >
}

export default BurgerApp;