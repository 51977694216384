import React from 'react';
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Breadcrumbs } from '@mui/material';
import RecruitTheme from '../recruit-theme';
import ProjectTheme from '../project-theme';

import ja_Trans from '../locales/ja/nav.json'
import en_Trans from '../locales/en/nav.json'
import cn_Trans from '../locales/cn/nav.json'

const BreadcrumbsApp: React.FC = () => {
    const { t } = useTranslation('nav');
    i18next.addResources('ja', 'nav', ja_Trans);
    i18next.addResources('en', 'nav', en_Trans);
    i18next.addResources('cn', 'nav', cn_Trans);
    const location = useLocation();
    const selectColor = () => location.pathname.indexOf("/recruit") ? ProjectTheme.palette.primary.light : RecruitTheme.palette.primary.light
    console.log("goto::", location.pathname);
    return (
        <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="flex-start">
            <Breadcrumbs maxItems={2} aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}
                style={
                    {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }
                }>
                <Box display="flex"
                    justifyContent="center" alignItems="center">
                    <Link to={"/"} style={{ textDecoration: 'none', color: selectColor() }}>
                        <Box component={HomeIcon}
                            alignSelf="center" justifySelf="center" mr="0.5em" />
                    </Link>
                    <Link to={"/"} style={{ textDecoration: 'none', color: selectColor() }}>
                        {t('ホーム')}
                    </Link>
                </Box>
                {[
                    { title: t('会社概要'), url: "/outline" },
                    { title: t('機密管理'), url: "/outline/secret" },
                    { title: t('アクセス'), url: "/outline/access" },
                    { title: t('検査ツール事業'), url: "/gauge" },
                    { title: t('検査ツールとは？'), url: "/gauge/fixture" },
                    { title: t('設備・開発フロー'), url: "/gauge/flow" },
                    { title: t('製品例'), url: "/gauge/product" },
                    { title: t('実績'), url: "/gauge/result" },
                    { title: t('概要'), url: "/digital" },
                    { title: t('デザイン'), url: "/digital/styledesign" },
                    { title: t('製品設計'), url: "/digital/design" },
                    { title: t('シミュレーション'), url: "/digital/simulation" },
                    { title: t('品質保証事業'), url: "/quality" },
                    { title: t('各種測定サービス'), url: "/quality/measure" },
                    { title: t('品質保証活動'), url: "/quality/activity" },
                    { title: t('お問い合わせフォーム'), url: "/gauge/flow/measure_form" },
                    { title: t('設備一覧'), url: "/gauge/flow/plant" },
                    { title: t('リクルート'), url: "/recruit" },
                    { title: t('募集要綱'), url: "/recruit/recruit" },
                    { title: t('先輩社員の声'), url: "/recruit/voice" },
                    { title: t('先輩社員の声１'), url: "/recruit/voice/detail01" },
                    { title: t('先輩社員の声２'), url: "/recruit/voice/detail02" },
                    { title: t('先輩社員の声３'), url: "/recruit/voice/detail03" },
                    { title: t('先輩社員の声４'), url: "/recruit/voice/detail04" },
                    { title: t('先輩社員の声５'), url: "/recruit/voice/detail05" },
                    { title: t('社内行事'), url: "/recruit/event" },
                    { title: t('サイトマップ'), url: "/sitemap" },
                ].filter((dict) => { return location.pathname.indexOf(dict.url) !== -1 }).map((PageItem, index) => {
                    return (<Link key={index} to={PageItem.url} style={{ textDecoration: 'none', color: selectColor() }}>
                        {PageItem.title}
                    </Link>);
                })
                }
            </Breadcrumbs>
        </Box>
    );
}

export default BreadcrumbsApp;