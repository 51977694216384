import { ListItem } from "@mui/material";
import React, { useEffect } from "react";

export type LanguageSelectorProps = {
    languages: { code: string, name: string }[],
    selectedLanguage: string,
    onLanguageSelectionChange: (lang: string) => void,
    small?: boolean,
}

export const LanugageSelector: React.FC<LanguageSelectorProps> = (props) => {
    useEffect(() => {

    }, [props.selectedLanguage])
    return <React.StrictMode>
        {props.languages.map((lang, index) => <ListItem key={index}
            onClick={() => props.onLanguageSelectionChange(lang.code)}
            style={{
                margin: props.small ? undefined : 0,
                padding: props.small ? undefined : 0,
                display: (lang.code === props.selectedLanguage) ? "none" : undefined,
            }}>
            {lang.name}
        </ListItem>)
        }
    </React.StrictMode >
}

export default LanugageSelector;