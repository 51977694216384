import React from 'react';
import aichibrand_logo_line from "../svg/aichibrand_logo_line.svg"

class AichiBrandMiniApp extends React.Component {
    render() {
        return (
            <React.StrictMode>
                <a href="https://www.aichi-brand.jp/" target="_blank" rel="noreferrer"
                    style={{
                        alignSelf: "center",
                        justifySelf: "center",
                    }} >
                    <img alt=""
                        src={aichibrand_logo_line}
                        width="auto"
                        height="26rem"
                        style={{ display: "inline-block" }}
                    />
                </a>
            </React.StrictMode>
        );
    }
}

export default AichiBrandMiniApp;

