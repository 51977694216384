import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ListItem } from '@mui/material';

import ja_Trans from '../locales/ja/nav.json'
import en_Trans from '../locales/en/nav.json'
import cn_Trans from '../locales/cn/nav.json'

const FixedMainNavApp: React.FC = () => {
    const { t } = useTranslation('nav');
    i18next.addResources('ja', 'nav', ja_Trans);
    i18next.addResources('en', 'nav', en_Trans);
    i18next.addResources('cn', 'nav', cn_Trans);
    return (
        <React.StrictMode>
            {[
                { title: t('会社概要'), url: "/outline" },
                { title: t('検査ツール事業'), url: "/gauge" },
                { title: t('デジタル事業'), url: "/digital" },
                { title: t('品質保証事業'), url: "/quality" },
                { title: t('リクルート'), url: "/recruit" },
                { title: t('サイトマップ'), url: "/sitemap" },
            ].map((PageItem, index) => {
                return (
                    <ListItem key={index} button
                        style={{
                            padding: 0, margin: 0,
                            fontSize: "0.9rem",
                            position: "relative",
                            width: "8rem",
                            height: "100%",
                            lineHeight: "46px",
                            textAlign: "center",
                            verticalAlign: "middle",
                        }}>
                        <Link to={PageItem.url} style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            textDecoration: "none",
                            color: "white",
                        }}>{PageItem.title}</Link>
                    </ListItem>
                )
            })}
        </React.StrictMode>
    )
}

export default FixedMainNavApp;