import ProjectTheme from "./project-theme";
import { createTheme } from "@mui/material";
import { blue } from "@mui/material/colors";

const RecruitTheme = createTheme(ProjectTheme, {
    palette: {
        primary: {
            main: blue[500],
            light: blue[300],
            dark: blue[800],
        },
    },
    typography: {
        h2: {
            borderBottomColor: String(blue[600]),
        },
        h3: {
            borderBottomColor: String(blue[600]),
            borderColor: String(blue[600]),
        },
    },
});

export default RecruitTheme;