import React from 'react'
import { Link } from 'react-router-dom'
import FixedMainNavApp from './nav_main_fixed'
import { LanugageSelector } from "./LanguageSelector"
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import { useEffect } from 'react';
import { lighten, Box } from '@mui/material'
import seamlessCarbon from '../svg/carbonseemlesspattern.svg'

import mainlogo from '../svg/greenfix_main_logo.svg'
import { isMobile } from 'react-device-detect'
import TemporaryDrawer from './drawer'

const languages = [{ code: "ja", name: "JAPANESE" }, { code: "en", name: "ENGLISH" }, { code: "cn", name: "CHINESE" }];

const HeaderApp: React.FC = () => {
  const [t, i18n] = useTranslation();
  const [currentLang, setCurrentLang] = useState("ja");
  // Change language of i18n
  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang, i18n])
  // Handler
  const handleLanguageSelectionChange = (lang: string) => {
    setCurrentLang(lang);
  }

  const black = "#000000FF"
  return (
    <Box component="header" height={isMobile ? "50px" : "46px"}
      flexDirection="row" display="flex"
      zIndex={100} overflow="visible" fontSize="0.8rem"
      color="white" width="100%"
      boxShadow={isMobile ? "0px 3px 6px rgba(0, 0, 0, 0.3)" : "10px"}
      style={{
        backgroundColor: "gray",
        backgroundBlendMode: "overlay",
        backgroundImage: isMobile
          ? `url(${seamlessCarbon}),linear-gradient(45deg, ${lighten(black, 0.2)}, ${lighten(black, 0.2)} 50%, ${lighten(black, 0.2)}, ${lighten(black, 0.6)} 70%, ${lighten(black, 0.1)} 80%, ${lighten(black, 0.1)})`
          : `url(${seamlessCarbon}),linear-gradient(45deg, ${lighten(black, 0.1)}, ${lighten(black, 0.2)} 10%, ${lighten(black, 0.6)} 20%, ${lighten(black, 0.2)}, ${lighten(black, 0.1)} 40%, ${lighten(black, 0.1)})`,
      }}>
      <Box display="flex" width="180px" height="100%">
        <Link to="/" style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: "100%",
          height: "100%",
        }}>
          <Box
            component="img"
            sx={{
              height: '50px - 10px * 2',
              width: '158px',
              borderRadius: '0.2pc'
            }}
            alt={t('グリーンフィクス株式会社')}
            src={mainlogo}
          />
        </Link>
      </Box>
      <Box component="nav" hidden={isMobile} height="100%" ml="auto" mr={0} mt={0} mb={0}>
        <Box component="li" height="100%" display="flex" m={0} p={0}>
          <FixedMainNavApp />
        </Box>
      </Box>
      <Box p={0} mr="20px" height="100%" display="flex" style={{ display: isMobile ? 'none' : undefined }}
        alignContent="center" alignItems="center" justifyContent="center">
        <Box component="ul" p={0} m={0}>
          <LanugageSelector languages={languages} selectedLanguage={currentLang} onLanguageSelectionChange={handleLanguageSelectionChange} />
        </Box>
      </Box>
      <TemporaryDrawer selector={
        <LanugageSelector languages={languages} small={true}
          selectedLanguage={currentLang} onLanguageSelectionChange={handleLanguageSelectionChange} />
      } />
    </Box >
  );

}

export default HeaderApp;
