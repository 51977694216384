import React from 'react'
import FixedSubNavApp from './nav_sub_fixed'
import SubNavReactApp from './nav_sub_react'
import { Divider, Drawer, List, lighten, Box } from '@mui/material'
import seamlessCarbon from '../svg/carbonseemlesspattern.svg'
import BurgerApp from './burger'
import { isMobile } from 'react-device-detect'

export type TemporaryDrawerProps = {
  selector: React.ReactElement,
}

const TemporaryDrawer: React.FC<TemporaryDrawerProps> = (props: TemporaryDrawerProps) => {
  const [openDrawerState, setOpenDrawerState] = React.useState(false);
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpenDrawerState(open);
  };

  const list = (props: TemporaryDrawerProps) => (
    <Box color="white" role="presentation"
      width={250}
      minHeight="100%"
      maxHeight="200%"
      flex="inherit"
      display="flex"
      flexDirection="column"
      style={{
        backgroundColor: "gray",
        backgroundBlendMode: "overlay",
        backgroundImage:
          `url(${seamlessCarbon}),` +
          `linear-gradient(` +
          `45deg, ` +
          `${lighten("#000", 0.1)}, ` +
          `${lighten("#000", 0.2)} 10%, ` +
          `${lighten("#000", 0.6)} 20%, ` +
          `${lighten("#000", 0.2)}, ` +
          `${lighten("#000", 0.1)} 40%, ` +
          `${lighten("#000", 0.1)}` +
          `)`,
      }}>
      {[
        props.selector,
        <SubNavReactApp />,
        <FixedSubNavApp />
      ].map((item, index) => <React.StrictMode key={index}>
        <List onClick={toggleDrawer(false)}>
          {item}
        </List>
        <Divider />
      </React.StrictMode>)}
      <Box flexGrow={1} maxHeight='100%'></Box>
    </Box >
  );

  return (
    <React.Fragment key="left" >
      <Box position="relative" ml="auto" mr="10px" zIndex={10000} hidden={!isMobile}
        onClick={() => setOpenDrawerState(!openDrawerState)}>
        <BurgerApp checked={openDrawerState} />
      </Box>
      <Drawer anchor="left" open={openDrawerState} onClose={toggleDrawer(false)}
        style={{ minHeight: '100%', width: '100%' }}>
        {list(props)}
      </Drawer>
    </React.Fragment >
  );
}
export default TemporaryDrawer;
