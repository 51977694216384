import { ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import AichiBrandMiniApp from './aichibrand';

import ja_Trans from '../locales/ja/nav.json'
import en_Trans from '../locales/en/nav.json'
import cn_Trans from '../locales/cn/nav.json'

const FixedSubNavApp: React.FC = () => {
  const { t } = useTranslation('nav');
  i18next.addResources('ja', 'nav', ja_Trans);
  i18next.addResources('en', 'nav', en_Trans);
  i18next.addResources('cn', 'nav', cn_Trans);
  return (
    <React.StrictMode>
      <ListItem style={{ overflow: 'visible' }}>
        <ListItemText><AichiBrandMiniApp /></ListItemText>
      </ListItem>
      {[
        { title: t('検査ツールとは'), url: "/gauge/tool", internal: true },
        { title: t('製品例'), url: "/gauge/product", internal: true },
        { title: t('デジタル事例'), url: "/digital", internal: true },
        { title: t('リクルート'), url: "/recruit", internal: true },
        { title: t('ブログ'), url: "https://www.greenfix.co.jp/blog/", internal: false },
      ].map((PageItem, index) => {
        return (
          PageItem.internal
            ? <ListItem button key={index}
              component={Link} to={PageItem.url}>
              <ListItemText>
                {PageItem.title}
              </ListItemText>
            </ListItem >
            : <ListItem button key={index} onClick={() => { GoOutSide(PageItem.url) }}
            >
              <ListItemText>
                {PageItem.title}
              </ListItemText>
            </ListItem >
        )
      })}
    </React.StrictMode >
  );
}


const GoOutSide = (url: string) => {
  window.open(url, '_blank');
}

export default FixedSubNavApp;
