import { ThemeProvider } from '@mui/material/styles';
import projectTheme from './project-theme';
import reportWebVitals from './reportWebVitals';
import MainApp from "./components/pages/main";
import HeaderApp from "./components/header";
import FooterApp from "./components/footer";
import { HelmetProvider } from "react-helmet-async";

// i18n
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import jaCommon from './locales/ja/translation.json'
import enCommon from './locales/en/translation.json'
import cnCommon from './locales/cn/translation.json'
import { BrowserRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

if (window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}
i18next.use(initReactI18next).init({
  ns: ['common'],
  defaultNS: 'common',
  debug: false,
  resources: {
    ja: { common: jaCommon },
    en: { common: enCommon },
    cn: { common: cnCommon },
  },
  lng: 'ja',
  fallbackLng: 'ja',
  keySeparator: false,
  interpolation: { escapeValue: false }
});

function AppWithCallbackAfterRender() {
  useEffect(() => {
    console.log('rendered');
  });

  return <React.StrictMode>
    <BrowserRouter basename="/">
      <HelmetProvider>
        <ThemeProvider theme={projectTheme}>
          <HeaderApp></HeaderApp>
          <MainApp></MainApp>
          <FooterApp></FooterApp>
        </ThemeProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
